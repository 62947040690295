import { lazy } from "react";

const OrderReturnsPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/orderReturns/:order_id",
            component: lazy(() => import("./OrderReturns")),
        },
    ],
};

export default OrderReturnsPageConfig;
