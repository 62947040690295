import { lazy } from "react";

const AdminsPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/setup/admins/:type?",
      component: lazy(() => import("./Admins")),
    },
    {
      path: "/setup/addAdmin/:type?",
      component: lazy(() => import("./AddAdmin")),
    },
    {
      path: "/setup/editAdmin/:type?/:id",
      component: lazy(() => import("./EditAdmin")),
    },
  ],
};

export default AdminsPageConfig;
