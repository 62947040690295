import { lazy } from 'react';

const PlaceUserLocationFieldsPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/setup/placeUserLocationFields/:place_id',
      component: lazy(() => import('./PlaceUserLocationFieldsApp')),
      
    },
    {
      path: '/setup/addPlaceUserLocationFields/:place_id',
      component: lazy(() => import('./PlaceUserLocationFieldsAdd')),
      
    },
    {
      path: '/setup/editPlaceUserLocationFields/:place_id/:id?',
      component: lazy(() => import('./PlaceUserLocationFieldsEdit')),
      
    },
  ],
};

export default PlaceUserLocationFieldsPageConfig;
