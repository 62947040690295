import { lazy } from 'react';

const CurrenciesPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/setup/currencies',
      component: lazy(() => import('./CurrenciesApp')),
      
    },
    {
      path: '/setup/addCurrencies',
      component: lazy(() => import('./CurrenciesAdd')),
      
    },
    {
      path: '/setup/editCurrencies/:id?',
      component: lazy(() => import('./CurrenciesEdit')),
      
    },
  ],
};

export default CurrenciesPageConfig;
