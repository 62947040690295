import { lazy } from "react";

const IncompleteOrdersPageConfig  = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/setup/incompletedOrders/:date/:search_key?",
      component: lazy(() => import("./Orders")),
    },
  ],
};

export default IncompleteOrdersPageConfig;
