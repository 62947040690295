import { lazy } from "react";

const GiftcardsOrdersPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/giftcardsOrders/:from_date?/:to_date?/:Is_used?/:from_amount?/:to_amount?",
            component: lazy(() => import("./GiftcardsOrdersApp")),
        },
    ],
};

export default GiftcardsOrdersPageConfig;
