import { lazy } from "react";

const SubscriptionFeaturesPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/subscriptionFeatures/:subscription_id?",
            component: lazy(() => import("./SubscriptionFeatures")),
        },
        {
            path: "/setup/addSubscriptionFeature/:subscription_id?",
            component: lazy(() => import("./AddSubscriptionFeature")),
        },
        {
            path: "/setup/editSubscriptionFeature/:subscription_id?/:id",
            component: lazy(() => import("./EditSubscriptionFeature")),
        },
    ],
};

export default SubscriptionFeaturesPageConfig;
