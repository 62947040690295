import FuseNavigation from "@fuse/core/FuseNavigation";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import clsx from "clsx";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectNavigation } from "app/store/fuse/navigationSlice";
import { navbarCloseMobile } from "../../store/fuse/navbarSlice";

function Navigation(props) {
  const navigation = useSelector(selectNavigation);
  const [customNavigation, setCustomNavigation] = useState([...navigation]);
  const user = useSelector(({ auth }) => auth.user);
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("lg"));
  const dispatch = useDispatch();

  function handleItemClick(item) {
    if (mdDown) {
      dispatch(navbarCloseMobile());
    }
  }

  useEffect(() => {
    setCustomNavigation(navigation);
  }, [navigation]);

  useEffect(() => {
    if ((user && user.id === 22) || user.id === "22") {
      // add admins after the item.id = "users"
      let newNavigation = [...customNavigation];
      const adminsLink = {
        id: "admins",
        title: "Admins",
        type: "item",
        url: "/setup/admins",
        icon: "person",
      };
      const index = newNavigation[0].children.findIndex(
        (item) => item.id === "users"
      );
      newNavigation[0].children.splice(index + 1, 0, adminsLink);
      setCustomNavigation(newNavigation);
    }
  }, [user, navigation]);

  return (
    <FuseNavigation
      className={clsx("navigation", props.className)}
      navigation={customNavigation}
      layout={props.layout}
      dense={props.dense}
      active={props.active}
      onItemClick={handleItemClick}
    />
  );
}

Navigation.defaultProps = {
  layout: "vertical",
};

export default memo(Navigation);
