import { lazy } from "react";

const DeliveryCompanyPlacesPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/deliveryTypePlaces/:delivery_company_id?",
            component: lazy(() => import("./DeliveryCompanyPlaces")),
        },
        {
            path: "/setup/addDeliveryTypePlace/:delivery_company_id?",
            component: lazy(() => import("./AddDeliveryCompanyPlace")),
        },
        {
            path: "/setup/editDeliveryTypePlace/:delivery_company_id?/:id",
            component: lazy(() => import("./EditDeliveryCompanyPlace")),
        },
    ],
};

export default DeliveryCompanyPlacesPageConfig;
