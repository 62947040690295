import { lazy } from "react";

const deliveryMethodDriversPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/setup/deliveryMethodDrivers/:delivery_method_id",
      component: lazy(() => import("./DeliveryMethodDrivers")),
    },
    {
      path: "/setup/addDeliveryMethodDriver/:delivery_method_id",
      component: lazy(() => import("./AddDeliveryMethodDriver")),
    },
    {
      path: "/setup/editDeliveryMethodDriver/:delivery_method_id/:id",
      component: lazy(() => import("./EditDeliveryMethodDriver")),
    },
  ],
};

export default deliveryMethodDriversPageConfig;
