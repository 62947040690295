import { lazy } from "react";

const RawMaterialsPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/rawMaterials/:from_date?/:to_date?/:supplier_id?",
            component: lazy(() => import("./RawMaterials")),
        },
        {
            path: "/setup/addRawMaterials",
            component: lazy(() => import("./AddRawMaterials")),
        },
        {
            path: "/setup/editRawMaterials/:id",
            component: lazy(() => import("./EditRawMaterials")),
        },
    ],
};

export default RawMaterialsPageConfig;
