import { lazy } from "react";

const SubscriptionsPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/subscriptions",
            component: lazy(() => import("./Subscriptions")),
        },
        {
            path: "/setup/addSubscription",
            component: lazy(() => import("./AddSubscription")),
        },
        {
            path: "/setup/editSubscription/:id",
            component: lazy(() => import("./EditSubscription")),
        },
    ],
};

export default SubscriptionsPageConfig;
