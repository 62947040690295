import { lazy } from "react";

const MonthsPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/months",
            component: lazy(() => import("./Months")),
        },
        {
            path: "/setup/AddMonth",
            component: lazy(() => import("./AddMonths")),
        },
        {
            path: "/setup/EditMonth/:id",
            component: lazy(() => import("./EditMonths")),
        },
    ],
};

export default MonthsPageConfig;
