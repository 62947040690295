import { lazy } from "react";

const OrdersPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/setup/orders/:from_date?/:to_date?/:status?/:store_id?/:category_id?/:place_id?/:payment_method?/:option_id?/:option_value_id?/:product_id?/:with_return?/:with_exchange?/:from_id?/:to_id?/:search_key?",
      component: lazy(() => import("./Orders")),
    },
    {
      path: "/setup/ordersWithReturn/:with_return?",
      component: lazy(() => import("./Orders")),
    },
    {
      path: "/setup/ordersWithExchange/:with_exchange?",
      component: lazy(() => import("./Orders")),
    },
    {
      path: "/setup/ordersWithReview/:with_review?",
      component: lazy(() => import("./Orders")),
    },
    {
      path: "/setup/addOrder",
      component: lazy(() => import("./AddOrder")),
    },
    {
      path: "/setup/editOrder/:id",
      component: lazy(() => import("./EditOrder")),
    },
    {
      path: "/setup/orderDetails/:id/:date?",
      component: lazy(() => import("./order/OrderDetails")),
    },
    {
      path: "/setup/ordersAdvanceFilter",
      component: lazy(() => import("./OrderFilterPage")),
    },
  ],
};

export default OrdersPageConfig;
