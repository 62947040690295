import { lazy } from "react";

const DeliveryMethodsPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/setup/deliveryMethods",
      component: lazy(() => import("./DeliveryMethods")),
    },
    {
      path: "/setup/addDeliveryMethod",
      component: lazy(() => import("./AddDeliveryMethod")),
    },
    {
      path: "/setup/editDeliveryMethod/:id",
      component: lazy(() => import("./EditDeliveryMethod")),
    },
  ],
};

export default DeliveryMethodsPageConfig;
