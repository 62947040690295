import { lazy } from "react";

const SubscriptionOrdersPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/subscriptionOrders/:from_date?/:to_date?/:status?/:subscription_id?/:payment_method_id?",
            component: lazy(() => import("./SubscriptionOrders")),
        },
        {
            path: "/setup/addSubscriptionOrder",
            component: lazy(() => import("./AddSubscriptionOrder")),
        },
        // {
        //     path: "/setup/editOrder/:id",
        //     component: lazy(() => import("./EditOrder")),
        // },
        {
            path: "/setup/SubscriptionOrderDetails/:id",
            component: lazy(() => import("./order/SubscriptionOrderDetails")),
        },
    ],
};

export default SubscriptionOrdersPageConfig;
