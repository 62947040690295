import { lazy } from "react";

const RawMaterialPurchasesPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/rawMaterialPurchases/:raw_material_id?",
            component: lazy(() => import("./RawMaterialPurchases")),
        },
        {
            path: "/setup/addRawMaterialPurchase/:raw_material_id?",
            component: lazy(() => import("./AddRawMaterialPurchase")),
        },
        {
            path: "/setup/editRawMaterialPurchase/:raw_material_id?/:id",
            component: lazy(() => import("./EditRawMaterialPurchase")),
        },
    ],
};

export default RawMaterialPurchasesPageConfig;
