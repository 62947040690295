import { lazy } from 'react';

const GiftcardsPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/setup/giftcards',
      component: lazy(() => import('./GiftcardsApp')),
      
    },
    {
      path: '/setup/addGiftcards',
      component: lazy(() => import('./GiftcardsAdd')),
      
    },
    {
      path: '/setup/editGiftcards/:id?',
      component: lazy(() => import('./GiftcardsEdit')),
      
    },
  ],
};

export default GiftcardsPageConfig;
