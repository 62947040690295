import PlacesPageConfig from "./setup/places/PlacesPageConfig";
import LanguagesPageConfig from "./setup/languages/LanguagesPageConfig";
import AppIntroPageConfig from "./setup/appIntro/AppIntroPageConfig";
import BrandsPageConfig from "./setup/brands/BrandsPageConfig";
import PublicPagesPageConfig from "./setup/publicPage/PublicPagesPageConfig";
import SlidersPageConfig from "./setup/sliders/SlidersPageConfig";
import HelpSectionsConfig from "./setup/helpSections/HelpSectionsConfig";
import BenefitsPageConfig from "./setup/benefits/BenefitsPageConfig";
import SocialMediaPageConfig from "./setup/socialMedia/SocialMediaPageConfig";
import PaymentMethodsPageConfig from "./setup/paymentMethods/PaymentMethodsPageConfig";
import GuidesPageConfig from "./setup/guides/GuidesPageConfig";
import CategoriesPageConfig from "./setup/categories/CategoriesPageConfig";
import StoresPageConfig from "./setup/stores/StoresPageConfig";
import ProductsPageConfig from "./setup/products/ProductsPageConfig";
import OptionsPageConfig from "./setup/options/OptionsPageConfig";
import OptionValuesPageConfig from "./setup/optionValues/OptionValuesPageConfig";
import ProductOptionsPageConfig from "./setup/productOptions/ProductOptionsPageConfig";
import UsersPageConfig from "./setup/users/UsersPageConfig";
import AdminsPageConfig from "./setup/admins/AdminsPageConfig";
import OrdersPageConfig from "./setup/orders/OrdersPageConfig";
import CampaignsPageConfig from "./setup/campaigns/CampaignsPageConfig";
import DeliveryCompanyPlacesPageConfig from "./setup/deliveryCompanies/deliveryCompanyPlaces/DeliveryCompanyPlacesPageConfig";
import DeliveryCompaniesStatementPageConfig from "./setup/deliveryCompanies/deliveryCompaniesStatement/DeliveryCompaniesStatementPageConfig";
import DeliveryCompaniesSettlementPageConfig from "./setup/deliveryCompanies/deliveryCompaniesSettlements/DeliveryCompaniesSettlementPageConfig";
import ProductImagesPageConfig from "./setup/products/productImages/ProductImagesPageConfig";
import DeliveryCompaniesPageConfig from "./setup/deliveryCompanies/DeliveryCompaniesPageConfig";
import ProductSpecificationPageConfig from "./setup/products/productSpecifications/ProductSpecificationPageConfig";
import ProductReviewsPageConfig from "./setup/products/productReviews/ProductReviewsPageConfig";
import ProductRelatedPageConfig from "./setup/products/productRelated/ProductRelatedPageConfig";
import CouponsPageConfig from "./setup/coupons/CouponsPageConfig";
import TaxesPageConfig from "./setup/taxes/TaxesPageConfig";
import CommissionsPageConfig from "./setup/commissions/CommissionsPageConfig";
import LabelsPageConfig from "./setup/labels/LabelsPageConfig";
import StorePlacesPageConfig from "./setup/stores/storePlaces/StorePlacesPageConfig";
import StoreUsersPageConfig from "./setup/stores/storeUsers/StoreUsersPageConfig";
import StoreStatementPageConfig from "./setup/stores/storeStatement/StoreStatementPageConfig";
import StoreSettlementsPageConfig from "./setup/stores/storeSettlements/StoreSettlementsPageConfig";
import CouponRestrictionsPageConfig from "./setup/coupons/couponRestrictions/CouponRestrictionsPageConfig";
import SettingsPageConfig from "./setup/settings/SettingsPageConfig";
import AdsPageConfig from "./setup/ads/AdsPageConfig";
import CollectionsPageConfig from "./setup/collections/CollectionsPageConfig";
import CurrenciesPageConfig from "./setup/currencies/CurrenciesPageConfig";
import GiftcardsPageConfig from "./setup/giftcards/GiftcardsPageConfig";
import GiftcardsOrdersPageConfig from "./setup/giftcardsOrders/GiftcardsOrdersPageConfig";
import PlaceUserLocationFieldsPageConfig from "./setup/placeUserLocationFields/PlaceUserLocationFieldsPageConfig";
import RawMaterialsPageConfig from "./setup/rawMaterials/RawMaterialsPageConfig";
import LoyaltyTiersPageConfig from "./setup/loyaltyTiers/LoyaltyTiersPageConfig";
import TierRewardsPageConfig from "./setup/loyaltyTiers/TierRewards/TierRewardsPageConfig";
import MonthsPageConfig from "./setup/months/MonthsPageConfig";
import OrderExchangePageConfig from "./setup/OrderExchange/OrderExchangePageConfig";
import OrderReturnsPageConfig from "./setup/OrderReturns/OrderReturnsPageConfig";
import OrderRefundReasonsPageConfig from "./setup/OrderRefundReason/OrderRefundReasonPageConfig";
import SubscriptionOrdersPageConfig from "./setup/subscriptionOrders/SubscriptionOrdersPageConfig";
import SubscriptionsPageConfig from "./setup/subscriptions/SubscriptionsPageConfig";
import SubscriptionGroupsPageConfig from "./setup/subscriptions/subscriptionGroups/SubscriptionGroupsPageConfig";
import SubscriptionFieldsPageConfig from "./setup/subscriptions/subscriptionFields/SubscriptionFieldsPageConfig";
import SubscriptionFeaturesPageConfig from "./setup/subscriptions/subscriptionFeatures/SubscriptionFeaturesPageConfig";
import RawMaterialSuppiersPageConfig from "./setup/rawMaterialSuppiers/RawMaterialSuppiersPageConfig";
import RawMaterialPurchasesPageConfig from "./setup/rawMaterials/rawMaterialPurchases/RawMaterialPurchasesPageConfig";
import ProductHasInsidesPageConfig from "./setup/products/productHasInsides/ProductHasInsidesPageConfig";
import ContactsPageConfig from "./setup/contacts/ContactsPageConfig";
import PrintBulkOrdersConfig from "./setup/printBulkOrders/PrintBulkOrdersConfig";
import IncompleteOrdersPageConfig from "./setup/incompletedOrders/IncompleteOrdersPageConfig";
import DeliveryMethodsPageConfig from "./setup/deliveryMethods/DeliveryMethodsPageConfig";
import deliveryMethodDriversPageConfig from "./setup/deliveryMethodsDrivers/deliveryMethodDriversPageConfig";

const screensConfigs = [
  PlacesPageConfig,
  RawMaterialSuppiersPageConfig,
  RawMaterialPurchasesPageConfig,
  OrderRefundReasonsPageConfig,
  SubscriptionOrdersPageConfig,
  SubscriptionFieldsPageConfig,
  SubscriptionGroupsPageConfig,
  SubscriptionsPageConfig,
  SubscriptionFeaturesPageConfig,
  MonthsPageConfig,
  OrderReturnsPageConfig,
  OrderExchangePageConfig,
  CollectionsPageConfig,
  StoreUsersPageConfig,
  LoyaltyTiersPageConfig,
  TierRewardsPageConfig,
  SettingsPageConfig,
  // DeliveryCompaniesStatementPageConfig,
  // DeliveryCompaniesSettlementPageConfig,
  StoreStatementPageConfig,
  StoreSettlementsPageConfig,
  CommissionsPageConfig,
  LabelsPageConfig,
  StorePlacesPageConfig,
  TaxesPageConfig,
  LanguagesPageConfig,
  OrdersPageConfig,
  ProductReviewsPageConfig,
  CampaignsPageConfig,
  CouponRestrictionsPageConfig,
  AppIntroPageConfig,
  ProductImagesPageConfig,
  DeliveryCompaniesPageConfig,
  DeliveryCompanyPlacesPageConfig,
  ProductSpecificationPageConfig,
  BrandsPageConfig,
  PublicPagesPageConfig,
  UsersPageConfig,
  AdminsPageConfig,
  ProductRelatedPageConfig,
  SlidersPageConfig,
  HelpSectionsConfig,
  ProductOptionsPageConfig,
  OptionValuesPageConfig,
  OptionsPageConfig,
  BenefitsPageConfig,
  SocialMediaPageConfig,
  PaymentMethodsPageConfig,
  GuidesPageConfig,
  CategoriesPageConfig,
  StoresPageConfig,
  ProductsPageConfig,
  AdsPageConfig,
  CouponsPageConfig,
  CurrenciesPageConfig,
  GiftcardsPageConfig,
  GiftcardsOrdersPageConfig,
  PlaceUserLocationFieldsPageConfig,
  RawMaterialsPageConfig,
  ProductHasInsidesPageConfig,
  ContactsPageConfig,
  PrintBulkOrdersConfig,
  IncompleteOrdersPageConfig,
  DeliveryMethodsPageConfig,
  deliveryMethodDriversPageConfig,
];

export default screensConfigs;
