import { createSlice } from "@reduxjs/toolkit";
import { showMessage } from "app/store/fuse/messageSlice";
import firebaseService from "app/services/firebaseService";
import authService from "app/services/authService";
import { setUserData } from "./userSlice";

export const submitLogin =
    ({ email, password }) =>
    async (dispatch, getState) => {
        return authService
            .signInWithEmailAndPassword(email, password)
            .then((user) => {
                const fuseDefaultSettings = getState().fuse.settings.defaults;
                const userData = _.merge({}, user, {
                    uid: user.id,
                    from: "API",
                    role: ["admin"],
                    data: {
                        displayName: user.name,
                        email: user.email,
                        settings: { ...fuseDefaultSettings },
                    },
                });
                dispatch(setUserData(userData));

                return dispatch(loginSuccess());
            })
            .catch((errors) => {
                return dispatch(loginError(errors));
            });
    };

const initialState = {
    success: false,
    errors: [],
};

const loginSlice = createSlice({
    name: "auth/login",
    initialState,
    reducers: {
        loginSuccess: (state, action) => {
            state.success = true;
            state.errors = [];
        },
        loginError: (state, action) => {
            state.success = false;
            state.errors = action.payload;
        },
    },
    extraReducers: {},
});

export const { loginSuccess, loginError } = loginSlice.actions;

export default loginSlice.reducer;
