import { lazy } from "react";

const DeliveryCompaniesPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/setup/deliveryTypes",
      component: lazy(() => import("./DeliveryCompanies")),
    },
    {
      path: "/setup/addDeliveryType",
      component: lazy(() => import("./AddDeliveryCompanie")),
    },
    {
      path: "/setup/editDeliveryType/:id",
      component: lazy(() => import("./EditDeliveryCompanie")),
    },
  ],
};

export default DeliveryCompaniesPageConfig;
