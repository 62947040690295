import { authRoles } from "app/auth";
import i18next from "i18next";
import DocumentationNavigation from "../main/documentation/DocumentationNavigation";

import ar from "./navigation-i18n/ar";
import en from "./navigation-i18n/en";
import tr from "./navigation-i18n/tr";

i18next.addResourceBundle("en", "navigation", en);
i18next.addResourceBundle("tr", "navigation", tr);
i18next.addResourceBundle("ar", "navigation", ar);

const navigationConfig = [
  {
    id: "pages",
    title: "Pages",
    type: "group",
    icon: "pages",
    children: [
      {
        id: "dashboards",
        title: "Dashboards",
        translate: "DASHBOARDS",
        type: "item",
        url: "/apps/dashboards/project",
        icon: "dashboard",
      },
      {
        id: "setup",
        title: "Setup",
        translate: "SETUP",
        type: "collapse",
        icon: "engineering",
        children: [
          {
            id: "places",
            title: "Places",
            type: "item",
            url: "/setup/places",
            exact: true,
          },
          {
            id: "languages",
            title: "Languages",
            type: "item",
            url: "/setup/languages",
            exact: true,
          },
          {
            id: "currencies",
            title: "Currencies",
            type: "item",
            url: "/setup/currencies",
            exact: true,
          },
          {
            id: "contact",
            title: "Contact",
            type: "item",
            url: "/setup/contacts",
            exact: true,
          },
          {
            id: "publicPages",
            title: "Public Pages",
            type: "item",
            url: "/setup/publicPages",
            exact: true,
          },
          {
            id: "sliders",
            title: "Sliders",
            type: "item",
            url: "/setup/sliders",
            exact: true,
          },
          {
            id: "socialMedia",
            title: "Social Media",
            type: "item",
            url: "/setup/SocialMedia",
            exact: true,
          },
          {
            id: "paymentMethods",
            title: "Payment Methods",
            type: "item",
            url: "/setup/paymentMethods",
            exact: true,
          },
          {
            id: "orderRefundReason",
            title: "Order Refund Reason",
            type: "item",
            url: "/setup/orderRefundReason",
            exact: true,
          },
        ],
      },
      {
        id: "products",
        title: "Products",
        icon: "headset",
        type: "item",
        url: "/setup/products",
      },
      {
        id: "loyaltyTiers",
        title: "Loyalty Tiers",
        icon: "headset",
        type: "item",
        url: "/setup/loyaltyTiers",
      },

      {
        id: "orders",
        title: "Orders",
        icon: "backup_table",
        type: "item",
        url: "/setup/orders/0/0/0/0/0/0/0/0/0/0/0/0/0/0/0",
      },
      {
        id: "incompleteOrders",
        title: "Incompleted Orders",
        icon: "backup_table",
        type: "item",
        url: "/setup/incompletedOrders/0/0",
      },
      {
        id: "guides",
        title: "Guides",
        type: "item",
        url: "/setup/guides",
        icon: "style",
      },
      {
        id: "categories",
        title: "Categories",
        type: "item",
        url: "/setup/categories",
        icon: "category",
      },
      {
        id: "collections",
        title: "Collections",
        type: "item",
        url: "/setup/collections",
        icon: "collections_bookmark",
      },
      {
        id: "subscriptions",
        title: "Subscriptions",
        type: "item",
        url: "/setup/subscriptions",
        icon: "category",
      },

      {
        id: "subscriptionOrders",
        title: "Subscription Orders",
        type: "item",
        url: "/setup/subscriptionOrders",
        icon: "category",
      },

      {
        id: "giftcards",
        title: "Gift cards",
        icon: "headset",
        type: "item",
        url: "/setup/giftcards",
      },
      {
        id: "giftcardsOrders",
        title: "Gift cards Orders",
        icon: "headset",
        type: "item",
        url: "/setup/giftcardsOrders",
      },
      {
        id: "users",
        title: "Users",
        type: "item",
        url: "/setup/users",
        icon: "person",
      },
      {
        id: "months",
        title: "Months",
        type: "item",
        url: "/setup/months",
        icon: "person",
      },
      {
        id: "options",
        title: "Options",
        type: "item",
        url: "/setup/options",
        icon: "tune",
      },
      {
        id: "rawMaterials",
        title: "Raw Materials",
        type: "item",
        url: "/setup/rawMaterials",
        icon: "tune",
      },
      {
        id: "rawMaterialSuppiers",
        title: "Raw Materials Suppliers",
        type: "item",
        url: "/setup/rawMaterialSuppiers",
        icon: "tune",
      },
      {
        id: "campaigns",
        title: "Campaigns",
        type: "item",
        url: "/setup/campaigns",
        icon: "campaign",
      },
      {
        id: "deliveryCompanies",
        title: "Delivery Types",
        type: "item",
        url: "/setup/deliveryTypes",
        icon: "local_shipping",
      },
      {
        id: "deliveryMethods",
        title: "Delivery Methods",
        type: "item",
        url: "/setup/deliveryMethods",
        icon: "local_shipping",
      },
      {
        id: "coupons",
        title: "Coupons",
        type: "item",
        url: "/setup/coupons",
        icon: "card_giftcard",
      },
      {
        id: "taxes",
        title: "Taxes",
        type: "item",
        url: "/setup/taxes",
        icon: "request_quote",
      },
      {
        id: "commissions",
        title: "Commissions",
        type: "item",
        url: "/setup/commissions",
        icon: "donut_large",
      },
      {
        id: "labels",
        title: "Labels",
        type: "item",
        url: "/setup/labels",
        icon: "local_offer",
      },
      {
        id: "ads",
        title: "Ads",
        type: "item",
        url: "/setup/ads",
        icon: "panorama",
      },
      {
        id: "mystreyBox",
        title: "Mystrey Box",
        type: "item",
        url: "/setup/products/0/51",
        icon: "panorama",
      },
      {
        id: "settings",
        title: "Settings",
        type: "item",
        url: "/setup/settings",
        icon: "settings",
      },
      {
        id: "reports",
        title: "Reports",
        type: "item",
        url: "/apps/dashboards/report",
        icon: "reports",
      },
    ],
  },
];

export default navigationConfig;
