import { lazy } from "react";

const SubscriptionFieldsPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/subscriptionFields/:subscription_id?",
            component: lazy(() => import("./SubscriptionFields")),
        },
        {
            path: "/setup/addSubscriptionField/:subscription_id?",
            component: lazy(() => import("./AddSubscriptionField")),
        },
        {
            path: "/setup/editSubscriptionField/:subscription_id?/:id",
            component: lazy(() => import("./EditSubscriptionField")),
        },
    ],
};

export default SubscriptionFieldsPageConfig;
