import { lazy } from "react";

const ProductHasInsidesPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/productHasInsides/:product_id?",
            component: lazy(() => import("./ProductHasInsides")),
        },
        {
            path: "/setup/addProductHasInsides/:product_id?",
            component: lazy(() => import("./AddProductHasInsides")),
        },
    ],
};

export default ProductHasInsidesPageConfig;
