import { lazy } from "react";

const SubscriptionGroupsPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/subscriptionGroups/:subscription_id?",
            component: lazy(() => import("./SubscriptionGroups")),
        },
        {
            path: "/setup/addSubscriptionGroup/:subscription_id?",
            component: lazy(() => import("./AddSubscriptionGroup")),
        },
        {
            path: "/setup/editSubscriptionGroup/:subscription_id?/:id",
            component: lazy(() => import("./EditSubscriptionGroup")),
        },
    ],
};

export default SubscriptionGroupsPageConfig;
