import { lazy } from "react";

const LoyaltyTiersPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/loyaltyTiers",
            component: lazy(() => import("./LoyaltyTiers")),
        },
        {
            path: "/setup/addLoyaltyTier",
            component: lazy(() => import("./AddLoyaltyTiers")),
        },
        {
            path: "/setup/editLoyaltyTier/:id",
            component: lazy(() => import("./EditLoyaltyTiers")),
        },
    ],
};

export default LoyaltyTiersPageConfig;
