import { lazy } from "react";

const ContactsPageConfig = {
    contacts: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/contacts",
            component: lazy(() => import("./ContactsApp")),
        },
    ],
};

export default ContactsPageConfig;
