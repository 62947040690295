import { lazy } from "react";

const TierRewardsPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/tierRewards/:tier_id",
            component: lazy(() => import("./TierRewards")),
        },
        {
            path: "/setup/addTierReward/:tier_id",
            component: lazy(() => import("./AddTierRewards")),
        },
        {
            path: "/setup/editTierReward/:id/:tier_id",
            component: lazy(() => import("./EditTierRewards")),
        },
    ],
};

export default TierRewardsPageConfig;
