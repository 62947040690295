import { lazy } from "react";

const RawMaterialSuppiersPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/rawMaterialSuppiers",
            component: lazy(() => import("./RawMaterialSuppiers")),
        },
        {
            path: "/setup/addRawMaterialSuppier",
            component: lazy(() => import("./AddRawMaterialSuppier")),
        },
        {
            path: "/setup/editRawMaterialSuppier/:id",
            component: lazy(() => import("./EditRawMaterialSuppier")),
        },
    ],
};

export default RawMaterialSuppiersPageConfig;
