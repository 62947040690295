import { lazy } from "react";

const PrintBulkOrdersConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/print-bulk-orders/:orders_ids",
            component: lazy(() => import("./PrintBulkOrders")),
        },
    ],
};

export default PrintBulkOrdersConfig;
