import { lazy } from "react";

const OrderRefundReasonsPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/orderRefundReason",
            component: lazy(() => import("./OrderRefundReason")),
        },
        {
            path: "/setup/addOrderRefundReason",
            component: lazy(() => import("./AddOrderRefundReason")),
        },
        {
            path: "/setup/editOrderRefundReason/:id",
            component: lazy(() => import("./EditOrderRefundReason")),
        },
    ],
};

export default OrderRefundReasonsPageConfig;
