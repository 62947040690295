import { lazy } from "react";

const OrderExchangePageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/orderExchange/:order_id",
            component: lazy(() => import("./OrderExchange")),
        },
    ],
};

export default OrderExchangePageConfig;
