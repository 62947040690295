import { lazy } from "react";

const ProductsPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/products/:store_id?/:category_id?/:with_review?/:status?",
            component: lazy(() => import("./Products")),
        },
        {
            path: "/setup/addProduct/:store_id?/:category_id?",
            component: lazy(() => import("./AddProduct")),
        },
        {
            path: "/setup/editProduct/:id?/:store_id?/:category_id?",
            component: lazy(() => import("./EditProduct")),
        },
        {
            path: "/setup/productDetailsDetails/:product_id?/:id?/:category_id?",
            component: lazy(() => import("../productsAdvance/ProductsAdvance/ProductDetails")),
        },
        {
            path: "/setup/importProducts",
            component: lazy(() => import("./Import")),
        },
    ],
};

export default ProductsPageConfig;
